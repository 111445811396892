import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from 'src/components/Page';
import { RegisterForm } from 'src/portals/components/auth/register';
import BackgroundImage from 'src/assets/PageBackground.png';
import useFeatureFlags from 'src/hooks/useFeatureFlags';

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
  backgroundImage: `url(${BackgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

export default function RegisterCustomer({ partnerMode }: { partnerMode: boolean }) {
  const featureFlags = useFeatureFlags();

  const underMaintenance = featureFlags.isFeatureEnabled(
    featureFlags.featureFlagKeys.underMaintenance
  );

  if (underMaintenance) {
    return null;
  }

  return (
    <Page title="Libertas - Customer Portal">
      <RootStyle role={'main'}>
        <Container maxWidth="lg">
          <Grid container spacing={8}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h5" sx={{ color: '#fff', textAlign: 'center' }}>
                Register to Apply
              </Typography>
              <RegisterForm role={'customer'} showLegalAgreements={true} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box
                sx={{
                  backgroundColor: '#132724',
                  opacity: '0.95',
                  borderRadius: '4px',
                  mt: '48px',
                  p: '48px',
                }}
              >
                <Typography variant="h2" sx={{ color: '#34E9AC', fontWeight: 'normal' }}>
                  Where Relationships Are An Asset
                </Typography>

                {partnerMode ? (
                  <Typography variant="body2" sx={{ color: '#FFF', mt: '2rem' }}>
                    Founded in 2016, Libertas is a high-tech, high-touch specialty finance and asset
                    management company with a focus on small and medium-sized businesses (SMBs).
                    Libertas' Cash Flow-Based Financings (CFBFs) is non-dillutive, providing
                    mezzanine-like financing rates without hard collateral or restrictive covenants.
                  </Typography>
                ) : (
                  <Typography variant="body2" sx={{ color: '#FFF', mt: '2rem' }}>
                    Fund your future without taking on debt or dilution. Our revenue-based funding
                    solutions allow expanding businesses to turn recurring revenues into essential
                    growth capital.
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  );
}
